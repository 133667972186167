.cupid__landing{
display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.cupid__content{
    background: rgb(255, 255, 255, 0.8);
    padding: 2rem;
    border-radius: 10px;
    margin: 15rem;
}

@media screen and (max-width:970px) {
  .cupid__content{
    margin: 4rem;
}
}

@media screen and (max-width:565px) {
  .cupid__content{
    margin: 2rem;
}
}
  


button {
    background-color: #FF4033;
    color: #fff;
    padding: 15px 30px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: all 1s ease-in-out;
    margin: 0.5rem;
  }

  button:hover {
    transform: scale(1.1);
  }

  .popup-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }

  @media screen and (max-width:672px) {
    .popup-container {
      width: 80%;

    }
  }

  .pop-up-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }


  
  
  